@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Raleway', sans-serif;
}
